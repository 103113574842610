// Angular
import { Component, OnInit, ViewChild, Inject, Output, EventEmitter, ChangeDetectorRef, NgModule } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import icDateRange from '@iconify/icons-ic/date-range';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPerson from '@iconify/icons-ic/twotone-person';
import icMyLocation from '@iconify/icons-ic/twotone-my-location';
import icLocationCity from '@iconify/icons-ic/twotone-location-city';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icFlag from '@iconify/icons-ic/flag';
import icTimer from '@iconify/icons-ic/timer';
import icTimerOff from '@iconify/icons-ic/timer-off';
import icVisibility from '@iconify/icons-ic/visibility';
import icVisibilityOff from '@iconify/icons-ic/visibility-off';
import icAdd from '@iconify/icons-ic/add';
import icRemove from '@iconify/icons-ic/remove-circle-outline';

import { ReservaService } from '../../../../services/Reservas.service';
import { AuthService } from '../../../../../app/core/auth';
import { TranslateService } from '@ngx-translate/core';
import { NotificationDialog } from './NotificationDialog.component';

import { LoadingFreeze } from './LoadingFreeze';
import {Customer} from "../../../../../../../demo/src/app/pages/apps/aio-table/interfaces/customer.model";


@Component({

    selector: 'NOX-Simulator-Dialog',
    templateUrl: './Simulator.component.html',
	styles: [
		':host ::ng-deep .mat-form-field-infix { width: auto ; } ' +
		':host ::ng-deep .removeLine {margin: auto;} ' +
		':host ::ng-deep .rounded.bg-app-bar { background-color: #ebebee73; }' +
		'.vex-style-dark :host ::ng-deep  .rounded.bg-app-bar { background-color: #11151e96; }']
})

export class SimulatorDialog implements OnInit {

    @Output() submitClicked = new EventEmitter<any>();

	form: FormGroup;

	inputType = 'text';
	inputType2 = 'number';
	visible = false;

	icDateRange = icDateRange;
	icMoreVert = icMoreVert;
	icClose = icClose;

	icPrint = icPrint;
	icDownload = icDownload;
	icDelete = icDelete;

	icPerson = icPerson;
	icMyLocation = icMyLocation;
	icLocationCity = icLocationCity;
	icEditLocation = icEditLocation;
	icPhone = icPhone;
	icFlag = icFlag;
	icTimer = icTimer;
	icTimerOff = icTimerOff;
	icVisibility = icVisibility;
	icVisibilityOff = icVisibilityOff;
	icAdd = icAdd;
	icRemove = icRemove;

	Contact: any = {};

	rows: any;

    constructor(public dialogRef: MatDialogRef<SimulatorDialog>,
				public ReservasService: ReservaService,
				public auth: AuthService,
				public translate: TranslateService,
				public Dialog: MatDialog,
				private fb: FormBuilder,
				private cd: ChangeDetectorRef) {
    }

	ngOnInit() {
		this.form = this.fb.group({
			from: ['', Validators.required],
			to: ['', Validators.required],
			rows: this.fb.array([
				this.fb.group({
					weight: ['', Validators.required],
					length: [''],
					width: [''],
					heigth: [''],
					volume: ['', Validators.required]
				})
			])
			/*this.form = this.fb.group({
				from: ['', Validators.required],
				to: ['', Validators.required],
				rows: this.fb.array([
					this.fb.group({
						weight: ['', Validators.required],
						length: ['', Validators.required],
						width: ['', Validators.required],
						heigth: ['', Validators.required],
						volume: [{value: '', disabled: true}, Validators.required]
					})
				])*/
			/*weight: ['', Validators.required],
			length: ['', Validators.required],
			width: ['', Validators.required],
			heigth: ['', Validators.required],
			volume: [{value: '', disabled: true}, Validators.required],*/
		});

		this.rows = this.form.get('rows') as FormArray;

		/*this.form.controls['length'].valueChanges.subscribe(change => {
			this.updateVolume('length');
		});
		this.form.controls['width'].valueChanges.subscribe(change => {
			this.updateVolume('width');
		});
		this.form.controls['heigth'].valueChanges.subscribe(change => {
			this.updateVolume('heigth');
		});*/
	}

    onBackClick(): void {
        this.dialogRef.close();
    }

	addRow(): void {
    	//if(this.rows.length < 3) {
			/*const group = this.fb.group({
				weight: ['', Validators.required],
				length: ['', Validators.required],
				width: ['', Validators.required],
				heigth: ['', Validators.required],
				volume: [{value: '', disabled: true}, Validators.required]
			});*/

			const group = this.fb.group({
				weight: ['', Validators.required],
				length: [''],
				width: [''],
				heigth: [''],
				volume: ['', Validators.required]
			});

			this.rows.push(group);

			//this.rows = this.form.get('rows') as FormArray;
		/*} else {
			let RefNotDialog = this.Dialog.open(NotificationDialog,
				{
					width: '450px',
					disableClose: true,
					data: {
						Type: "Warning",
						Title: "",
						Description: 'Numero maximo de linhas atingido',
						Action: false,
						Action_Caption: "",
					}
				});
			return;
		}*/

    	/*let count = this.inputs.length;
		this.inputs.push({ id: count++, label: 'test' });*/
	}

	removeRow(key: number): void {
    	if(key > 0) {
			this.rows.removeAt(key);
		}
	}

	updateRow(el, i): void {
		const length = this.rows.controls[i].get('length').value
		const width = this.rows.controls[i].get('width').value
		const heigth = this.rows.controls[i].get('heigth').value
		if(length && width && heigth) {
			const calc = (length * 0.01) * (width * 0.01) * (heigth * 0.01 );
			this.rows.controls[i].get('volume').patchValue(calc.toFixed(3));
		}
	}

    onSaveClick(): void
    {
		let errors;
		//debugger;

		// Validar dados
		const controls = this.form.controls;
		const rows = this.rows.controls;
		let lines = [];
		/** check form */
		if (this.form.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			for(let i=0; i< rows.length; i++) {
				console.log(rows[i].controls);
				Object.keys(rows[i].controls).forEach(controlName =>
					rows[i].controls[controlName].markAsTouched()
				);
			}

			return;
		}

		for(let i=0; i< rows.length; i++) {

			if (rows[i].controls['volume'].value == 0) {
				let RefNotDialog = this.Dialog.open(NotificationDialog,
					{
						width: '450px',
						disableClose: true,
						data: {
							Type: "Warning",
							Title: "",
							Description: this.translate.instant('GERAL.VOLUMEERROR'),
							Action: false,
							Action_Caption: "",
						}
					});
				return;
			}

			lines.push({
				Packing:"VOL",
				Weight: rows[i].controls['weight'].value,
				Length: rows[i].controls['length'].value,
				Width: rows[i].controls['width'].value,
				Heigth: rows[i].controls['heigth'].value,
				Volume: rows[i].controls['volume'].value
			});
		}


		//this.loading = true;

		let toSave = {
			from: controls['from'].value,
			to: controls['to'].value,
			lines: lines
			/*weight: controls['weight'].value,
			length: controls['length'].value,
			width: controls['width'].value,
			heigth: controls['heigth'].value,
			volume: controls['volume'].value,*/
		};


			let W8Dialog = this.Dialog.open(LoadingFreeze,
				{
					panelClass: 'loading-freeze',
					disableClose: true ,
					width: '15%',
					data:{Title: '' }
				});


			this.ReservasService.Simulate(toSave).subscribe((result: any) => {

					W8Dialog.close();

					if (result.Success == true) {

						let RefNotDialog = this.Dialog.open(NotificationDialog,
							{
								width: '450px',
								disableClose: true,
								data: {
									Type: (result.Price && result.Price > 0) ? "Success" : "Warning",
									Title: "" ,
									Description: (result.Price && result.Price > 0) ?
										this.translate.instant('GERAL.EXPEDITIONCOST') + result.Price + '€' :
										'Sem resultados, contacte os Transportes Alves Barroco'
									//	Action: result.PrintLabels ? true : false,
									//	Action_Caption: this.translate.instant('RESERVES.PRINT_LABELS'),
								}
							});

						//this.dialogRef.close(toSave);

					} else {

						W8Dialog.close();

						let RefNotDialog = this.Dialog.open(NotificationDialog,
							{
								width: '450px',
								disableClose: true,
								data: {
									Type: "Error",
									Title: "",
									Description: result.Message,
									Action: false,
									Action_Caption: "",
								}
							});

					}

				},
				error => {
					W8Dialog.close();
					console.log(error);
					let RefNotDialog = this.Dialog.open(NotificationDialog,
						{
							width: '450px',
							disableClose: true,
							data: {
								Type: "Error",
								Title: "",
								Description: error.error.error,
								Action: false,
								Action_Caption: ''
							}
						});
				}
			);


    }

	toggleVisibility() {
		if (this.visible) {
			this.inputType = 'password';
			this.visible = false;
			this.cd.markForCheck();
		} else {
			this.inputType = 'text';
			this.visible = true;
			this.cd.markForCheck();
		}
	}

	CheckPassword(inputtxt) {
		let passw = /^\w{7,14}$/;
		if(inputtxt.match(passw))
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	keyPressNumbers(event) {
		var charCode = (event.which) ? event.which : event.keyCode;
		// Only Numbers 0-9
		if ((charCode < 48 || charCode > 57)) {
			event.preventDefault();
			return false;
		} else {
			return true;
		}
	}

	updateVolume(key: string) {
		if(key == 'length' || key == 'width' || key == 'heigth') {
			const length = this.form.get('length').value;
			const width = this.form.get('width').value;
			const heigth = this.form.get('heigth').value;
			if(length && width && heigth) {
				const calc = (length * 0.01) * (width * 0.01) * (heigth * 0.01 );
				this.form.get('volume').patchValue(calc.toFixed(3));
			}
		}
	}


}
