<form [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="start center" style="margin: 0 0 10px;" mat-dialog-title>

        <h2 class="title m-0"
            fxFlex="auto">{{ 'GERAL.SIMULATEEXPEDITION' | translate }}</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content fxLayout="column">

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">

            <mat-form-field class="mt-6" fxFlex="auto">
                <mat-label>{{'GERAL.FROMPOSTALCODE' | translate}}</mat-label>
                <input [type]="inputType" formControlName="from" autocomplete="off" matInput required cdkFocusInitial>
                <mat-hint>{{'GERAL.POSTALCODEEXAMPLE' | translate}}</mat-hint>
                <mat-error *ngIf="form.get('from').hasError('required')">{{'GERAL.FIELDREQUIRED' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="mt-6" fxFlex="auto">
                <mat-label>{{'GERAL.TOPOSTALCODE' | translate}}</mat-label>
                <input [type]="inputType" formControlName="to" autocomplete="off" matInput required>
                <mat-hint>{{'GERAL.POSTALCODEEXAMPLE' | translate}}</mat-hint>
                <mat-error *ngIf="form.get('to').hasError('required')">{{'GERAL.FIELDREQUIRED' | translate}}</mat-error>
            </mat-form-field>

        </div>

        <div class="rounded bg-app-bar" style="padding: 0 10px 10px 10px;">
            <div formArrayName="rows">
                <div *ngFor="let _ of rows.controls; index as i"
                     fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                    <ng-container [formGroupName]="i">
                        <mat-form-field class="mt-6" fxFlex="auto">
                            <mat-label>{{'GERAL.WEIGHT' | translate}}</mat-label>
                            <input [type]="inputType2" formControlName="weight" (keypress)="keyPressNumbers($event)"
                                   autocomplete="off" matInput required>
                            <mat-hint>{{'GERAL.INKG' | translate}}</mat-hint>
                            <mat-error *ngIf="rows.controls[i].get('weight').hasError('required')">
                                {{'GERAL.FIELDREQUIRED' | translate}}</mat-error>
                        </mat-form-field>

                        <mat-form-field class="mt-6" fxFlex="auto">
                            <mat-label>{{'GERAL.LENGTH' | translate}}</mat-label>
                            <input [type]="inputType2" formControlName="length" autocomplete="off"
                                   (input)="updateRow($event, i)" matInput>
                            <mat-hint>{{'GERAL.INCM' | translate}}</mat-hint>
                            <!--<mat-error *ngIf="rows.controls[i].get('length').hasError('required')">
                                {{'GERAL.FIELDREQUIRED' | translate}}</mat-error>-->
                        </mat-form-field>

                        <mat-form-field class="mt-6" fxFlex="auto">
                            <mat-label>{{'GERAL.WIDTH' | translate}}</mat-label>
                            <input [type]="inputType2" formControlName="width" autocomplete="off"
                                   (input)="updateRow($event, i)" matInput>
                            <mat-hint>{{'GERAL.INCM' | translate}}</mat-hint>
                            <!--mat-error *ngIf="rows.controls[i].get('width').hasError('required')">
                                {{'GERAL.FIELDREQUIRED' | translate}}</mat-error>-->
                        </mat-form-field>

                        <mat-form-field class="mt-6">
                            <mat-label>{{'GERAL.HEIGTH' | translate}}</mat-label>
                            <input [type]="inputType2" formControlName="heigth" autocomplete="off"
                                   (input)="updateRow($event, i)" matInput>
                            <mat-hint>{{'GERAL.INCM' | translate}}</mat-hint>
                            <!--<mat-error *ngIf="rows.controls[i].get('heigth').hasError('required')">
                                {{'GERAL.FIELDREQUIRED' | translate}}</mat-error>-->
                        </mat-form-field>

                        <mat-form-field class="mt-6">
                            <mat-label>{{'GERAL.VOLUME' | translate}}</mat-label>
                            <input [type]="inputType2" formControlName="volume" autocomplete="off"
                                   matInput required>
                            <mat-hint>Metros Cúbicos</mat-hint>
                            <mat-error *ngIf="rows.controls[i].get('volume').hasError('required')">
                                {{'GERAL.FIELDREQUIRED' | translate}}</mat-error>
                        </mat-form-field>

                        <button (click)="removeRow(i)" mat-icon-button class="removeLine" type="button">
                            <mat-icon [icIcon]="icRemove" class="ltr:-ml-1 rtl:-mr-1 ltr:mr-2 rtl:ml-2"
                                      inline="true" size="24px"></mat-icon>
                        </button>
                    </ng-container>
                </div>
            </div>

            <button (click)="addRow()" color="primary" mat-stroked-button type="button" class="mt-4 mb-2">
                <mat-icon [icIcon]="icAdd" class="ltr:-ml-1 rtl:-mr-1 ltr:mr-2 rtl:ml-2"
                          inline="true" size="24px"></mat-icon>Adicionar Linha</button>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button (click)="onBackClick()" mat-button mat-dialog-close type="button">{{'GERAL.BACK' | translate}}</button>
        <button (click)="onSaveClick()" color="primary" mat-button type="button">
            {{ 'GERAL.SIMULATE' | translate }}</button>
        <!--<button *ngIf="isUpdateMode()" color="primary" mat-button type="submit">UPDATE CUSTOMER</button>-->
    </mat-dialog-actions>
</form>

