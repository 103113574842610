import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';

// Env
import { environment } from '../environments/environment';
// Hammer JS
// import 'hammerjs';
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
// import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// State
import { metaReducers, reducers } from './core/reducers';

// Auth
import { LoginModule } from './views/pages/auth/login/login.module';
import { AuthService, AuthGuard , ValidExpedition } from './core/auth';
//import { AuthService, AuthGuard , ValidExpedition,PermissionEffects, permissionsReducer, RoleEffects, rolesReducer } from './core/auth';
// CRUD
// import { HttpUtilsService, LayoutUtilsService, TypesUtilsService,  } from './core/_base/crud';
import { CustomMatPaginatorIntl } from './shared/custom-mat-paginator-int';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgxPermissionsModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    LoginModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyASznaDK7OS_rkMm3axb-_RwIwWMCsuBAg'
    }),

    // Vex
    VexModule,
    CustomLayoutModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    ValidExpedition,
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http,"./assets/i18n/");
}
