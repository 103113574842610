import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { Reserva } from '../models/reserva.model';
import {NotificationDialog} from "../views/themes/geral/dialogs/NotificationDialog.component";


// Local
// const URL = 'https://portaltab.newoxygen.com/PortalClienteTestes/Portal.xsjs?cmd=';

// Production
//const URL = 'http://localhost:8080/proxy/Tab.php/PortalCliente/Portal.xsjs?cmd=';
//const URL = 'http://portal.alvesbarroco.pt/PortalCliente/Portal.xsjs?cmd=';
const URL = '/PortalCliente/Portal.xsjs?cmd=';

@Injectable()
export class ReservaService {
    constructor(private http: HttpClient,
                private translate: TranslateService) {}
    // Authentication/Authorization

   // reservas: Observable<Reserva[]>;


   _GetRequestBody() {
       let obj: any = {};

        obj.userid = localStorage.getItem('FioriUser');
        obj.fiori_token = localStorage.getItem(environment.authTokenKey);
        obj.lang = this.translate.currentLang;

        return obj;
   }


   Is_Online() {
       let header = new HttpHeaders();
       header.set('Access-Control-Allow-Origin', '*');

       let Body = this._GetRequestBody();

       return this.http.post(URL + 'Is_Online',Body, { headers: header });
   }

   Is_ValidToken() {
       let Body = this._GetRequestBody();

       return this.http.post(URL + 'Is_ValidToken',Body);
   }

   Get_Profile() {
       let Body = this._GetRequestBody();

       return this.http.post(URL + 'Get_Profile',Body);
   }


   Get_DashBoards() {
       let Body = this._GetRequestBody();

       return this.http.post(URL + 'Get_DashBoards',Body);
   }


   Localizar_Reserva(Search: string ) {
       let Body = this._GetRequestBody();

       Body.Search = Search;

       return this.http.post(URL + 'Localizar_Reserva',Body);
   }

   Localizar_ReservaExt(Search:string ) {
       let Body = this._GetRequestBody();

       Body.Search = Search;

       return this.http.post(URL+"Localizar_ReservaExt",Body);
   }

   Get_Countrys() {
       let Body = this._GetRequestBody();

       return this.http.post(URL + 'Get_Countrys',Body);
   }

   Get_PickingTypes() {
       let Body = this._GetRequestBody();

       return this.http.post(URL + 'Get_PickingTypes',Body);
   }

    Get_NatureTypes() {
        let Body = this._GetRequestBody();

        return this.http.post(URL + 'Get_NatureTypes',Body);
    }

   Get_Incoterms() {
       let Body = this._GetRequestBody();

       return this.http.post(URL + 'Get_Incoterms',Body);
   }


   Get_ClientsNOX() {
       let Body = this._GetRequestBody();

       return this.http.post(URL + 'Get_ClientsNOX',Body);
   }


   Get_ClientDetails() {
       let Body = this._GetRequestBody();

       return this.http.post(URL + 'Get_ClientDetails',Body);
   }



   Get_AddressesNOX(code: string) {
       let Body = this._GetRequestBody();

       Body.code = code; // Codigo do Expedidor / Destinatario

       return this.http.post(URL + 'Get_AddressesNOX',Body);
   }

   Set_ClientNOXDefault(Code: string, Checked: string) {
       let Body = this._GetRequestBody();

       Body.Code = Code;
       Body.Checked = Checked;

       return this.http.post(URL + 'Set_ClientNOXDefault',Body);
   }

   Set_AddressNOXDefault(Code: string , LineId: number ,Checked: string ) {
       let Body = this._GetRequestBody();

       Body.Code = Code;
       Body.LineId = LineId;
       Body.Checked = Checked;

       return this.http.post(URL + 'Set_AddressNOXDefault',Body);
   }

   Update_ClientAlerts(toSave: any) {
       let Body = this._GetRequestBody();

       Body.toSave = toSave;

       return this.http.post(URL + 'Update_ClientAlerts',Body);
   }

   Update_ClientOthers(toSave: any) {
       let Body = this._GetRequestBody();

       Body.toSave = toSave;

       return this.http.post(URL + 'Update_ClientOthers',Body);
   }


   Update_AddressNOX(Address: any) {
       let Body = this._GetRequestBody();

       Body.toSave = Address;

       console.log(Body);
       console.log(JSON.stringify(Body));

       return this.http.post(URL + 'Update_AddressNOX',Body);
   }




   Get_Reserve(DocNum: any) {
       let Body = this._GetRequestBody();

       Body.DocNum = DocNum;

       return this.http.post(URL + 'Get_Reserve',Body);
   }


   Get_ReservePOD(DocEntry: any) {
       let Body = this._GetRequestBody();

       Body.DocEntry = DocEntry;

       return this.http.post(URL + 'Get_ReservePOD',Body);
   }



   Save_Reserve(toSave: any) {
       let Body = this._GetRequestBody();

       Body.toSave = toSave;

       return this.http.post(URL + 'Save_Reserve',Body);
   }


   UploadCSV_Reserves(toSave: any) {
       let Body = this._GetRequestBody();

       Body.toSave = toSave;

       console.log(Body);
       console.log(JSON.stringify(Body));

       return this.http.post(URL + 'UploadCSV_Reserves',Body);
   }


   Update_Reserve(toSave: any) {
       let Body = this._GetRequestBody();

       Body.toSave = toSave;

       return this.http.post(URL + 'Update_Reserve',Body);
   }

   Delete_Reserve(toSave: any) {
       let Body = this._GetRequestBody();

       Body.DocEntry = toSave;

       return this.http.post(URL + 'Delete_Reserve',Body);
   }

   SubmitPrint_CliReserves(toPrint: any) {
       let Body = this._GetRequestBody();

       Body.toPrint = toPrint;  // São iguais os Arrays
       Body.toSubmit = toPrint;

       return this.http.post(URL + 'SubmitPrint_Reserves', Body );
   }




   Print_TemporaryMap(toRequest: any) {
       let Body = this._GetRequestBody();

       Body.toRequest = toRequest;  // São iguais os Arrays


       return this.http.post(URL + 'Print_TemporaryMap', Body );
   }

   Print_Guide(toRequest: any) {
        let Body = this._GetRequestBody();

        Body.toRequest = toRequest;  // São iguais os Arrays


        return this.http.post(URL + 'Print_Guide', Body );
   }

   Print_LabelsCrystal(toPrint: any) {
       let Body = this._GetRequestBody();

       Body.toPrint = toPrint;  // São iguais os Arrays

       return this.http.post(URL + 'Print_LabelsCrystal', Body );
   }



   New_CollectionRequest(toRequest: any) {
       let Body = this._GetRequestBody();

       Body.toRequest = toRequest;  // São iguais os Arrays

       return this.http.post(URL + 'New_CollectionRequest', Body );
   }


   Add_ClientNOX(toSave: any) {
       let Body = this._GetRequestBody();

       Body.toSave = toSave;

       return this.http.post(URL + 'Add_ClientNOX',Body);
   }


   Add_AddressNOX(toSave: any) {
       let Body = this._GetRequestBody();

       Body.toSave = toSave;

       return this.http.post(URL + 'Add_AddressNOX',Body);
   }

   ChangeDest_AddressNOX(toSave: any) {
       let Body = this._GetRequestBody();

       Body.toSave = toSave;

       return this.http.post(URL + 'ChangeDest_AddressNOX',Body);
   }


   Get_ReservesPicking(info: any) {
       let Body = this._GetRequestBody();

       return this.http.post(URL + 'Get_ReservesPicking', {... Body , ... info} ); // Ista junto as propriedades do Serviço
   }



   Save_PickRequest(toSave: any) {
       let Body = this._GetRequestBody();

       Body.toSave = toSave;

       return this.http.post(URL + 'Save_PickRequest',Body);
   }


   Update_PickRequest(toSave: any) {
       let Body = this._GetRequestBody();

       Body.toSave = toSave;

       return this.http.post(URL + 'Update_PickRequest',Body);
   }


   Get_PickRequest(DocNum: any) {
       let Body = this._GetRequestBody();

       Body.DocNum = DocNum;

       return this.http.post(URL + 'Get_PickRequest',Body);
   }

    Get_Reservas(Filters?) {

        let Body = this._GetRequestBody();

        if (Filters !== undefined) {
            Body.Filters  = Filters;

        }

        return this.http.post(URL + 'Get_Reservations', Body );
    }


    Get_InvoicesWithReserves(Filters?) {

        let Body = this._GetRequestBody();

        if (Filters !== undefined) {
            Body.Filters  = Filters;

        }

        return this.http.post(URL + 'Get_InvoicesWithReserves', Body );
    }



    Get_ReservasFAT(Filters?) {

        let Body = this._GetRequestBody();

        if (Filters !== undefined) {
            Body.Filters  = Filters;

        }

        return this.http.post(URL + 'Get_ReservationsFAT', Body );
    }



    Get_Incidencias(Filters?) {

        let Body = this._GetRequestBody();

        if (Filters !== undefined) {
            Body.Filters  = Filters;

        }

        return this.http.post(URL + 'Get_Incidences', Body );
    }

    Get_Faturas(Filters?) {

        let Body = this._GetRequestBody();

        if (Filters !== undefined) {
            Body.Filters  = Filters;

        }

        return this.http.post(URL + 'Get_Invoices', Body );
    }




    Get_Pickings(Filters?) {
        let Body = this._GetRequestBody();

        if (Filters !== undefined) {
            Body.Filters  = Filters;

        }

        return this.http.post(URL + 'Get_Pickings', Body );
    }



    Release_Expeditions(Expeditions: any) {
        let Body = this._GetRequestBody();

        Body.Expeditions = Expeditions;

        return this.http.post(URL + 'Release_Expeditions', Body );
    }


    Print_Labels(toPrint: any) {
        let Body = this._GetRequestBody();

        Body.toPrint = toPrint;

        return this.http.post(URL + 'Print_Labels', Body );
    }

    Print_Crystal(toPrint: any) {
        let Body = this._GetRequestBody();

        Body.toPrint = toPrint;

        return this.http.post(URL + 'Print_Crystal', Body );
    }


    Preview_LabelsZPL(toPrint: any) {
        let Body = this._GetRequestBody();

        Body.toPrint = toPrint;

        return this.http.post(URL + 'Preview_LabelsZPL', Body );
    }

    Get_ZPLText(toPrint: any) {
        let Body = this._GetRequestBody();

        Body.toPrint = toPrint;

        return this.http.post(URL + 'Get_ZPLText', Body );
    }


    Get_TraceTrack(DocEntry: any) {
        let Body = this._GetRequestBody();

        Body.DocEntry = DocEntry;

        return this.http.post(URL + 'Get_TrackTrace', Body );
    }

    Get_Notifications() {
        let Body = this._GetRequestBody();

        return this.http.post(URL + 'Get_Notifications', Body );

    }

    Notifications_MarkRead(Ids: any) {
        let Body = this._GetRequestBody();

        Body.Ids = Ids;

        return this.http.post(URL + 'Notifications_MarkRead', Body );
    }

    Get_Quotation(DocNum: any) {
        let Body = this._GetRequestBody();

        Body.DocNum = DocNum;

        return this.http.post(URL + 'Get_Quotation',Body);
    }

    Simulate(toSave: any) {
        let Body = this._GetRequestBody();

        Body.toSave = toSave;

        return this.http.post(URL + 'Simulate',Body);
    }
    
    getUserByToken(): Observable<Reserva> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const httpHeaders = new HttpHeaders();
        httpHeaders.append('Authorization', 'Bearer ' + userToken);
        return this.http.get<Reserva>(URL, { headers: httpHeaders },);
    }

    register(user: Reserva): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<Reserva>(URL, user, { headers: httpHeaders })
            .pipe(
                map((res: Reserva) => {
                    return res;
                }),
                catchError(err => {
                    return null;
                })
            );
    }

    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    public requestPassword(email: string): Observable<any> {
    	return this.http.get(URL + '/forgot?=' + email)
    		.pipe(catchError(this.handleError('forgot-password', []))
	    );
    }


    getAllUsers(): Observable<Reserva[]> {
		return this.http.get<Reserva[]>(URL);
    }

    getUserById(userId: number): Observable<Reserva> {
		return this.http.get<Reserva>(URL + `/${userId}`);
	}

	getRoleAccess(modName: string) {
        let access = false;
        let module = modName.toLowerCase().replace("component", "");
        let mods = localStorage.getItem('Modules');
        if(mods) {
            let modules = JSON.parse(atob(mods));
            access = modules.some(el => el.module === module);
        }
        return access;
    }

    getRoleContentAccess(modName: string, contentName: string) {
        let access;
        let content = false;
        let module = modName.toLowerCase().replace("component", "");
        let mods = localStorage.getItem('Modules');
        if(mods) {
            let modules = JSON.parse(atob(mods));
            access = modules.find(x => x.module === module)
            if(access) {
                content = access.content.includes(contentName);
            }
        }
        return content;
    }

    getVatDetails(vat) {
        let key = 'NOX2020';
        let protocol = 'https://';
        /*if (location.protocol == 'https:'){
            protocol = 'https';
        }*/
        return this.http.get(protocol+'nox.newoxygen.com/vat.php?vatno='+vat+'&a='+key);
    }

 	/*
 	 * Handle Http operation that failed.
 	 * Let the app continue.
     *
	 * @param operation - name of the operation that failed
 	 * @param result - optional value to return as the observable result
 	 */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
