import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { fadeInUp400ms } from '../../../../../@vex/animations/fade-in-up.animation';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { Style, StyleService } from '../../../../../@vex/services/style.service';
import { ColorVariable, colorVariables } from '../../../../../@vex/components/config-panel/color-variables';
import { DOCUMENT } from '@angular/common';

// Serviços
import { ReservaService } from '../../../../services/Reservas.service';
// RxJS
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, tap, catchError } from 'rxjs/operators';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login, User } from '../../../../core/auth';
import { NotificationDialog } from '../../../themes/geral/dialogs/NotificationDialog.component';

@Component({
  selector: 'vex-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInUp400ms
  ]
})
export class LoginComponent implements OnInit {

  form: FormGroup;

  inputType = 'password';
  visible = false;

  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;

  loading = false;
  private unsubscribe: Subject<any>;

  private returnUrl: any;

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Sign In',
    spinnerSize: 20,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    //spinnerColor: 'accent',
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  }

  constructor(private router: Router,
              private auth: AuthService,
              private authNoticeService: AuthNoticeService,
              private store: Store<AppState>,
              private fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private snackbar: MatSnackBar,
              private translate: TranslateService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private ReservasService: ReservaService,
              private styleService: StyleService,
              @Inject(DOCUMENT) private document: Document
  ) {
    this.unsubscribe = new Subject();
    this.translate.get(['Login'])
        .subscribe(translations => {
      this.spinnerButtonOptions.text = this.translate.instant('Login.SignIn');
    });
  }

  ngOnInit() {
    let userName = '';
    let rememberMe = false;
    let rememberUser = localStorage.getItem('FioriUsername');
    if(rememberUser)  {
      userName = rememberUser;
      rememberMe = true;
    }

    this.form = this.fb.group({
      email: [userName, Validators.required],
      password: ['', Validators.required],
      rememberMe: [rememberMe]
    });


    // redirect back to the returnUrl before login
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'] || '/';
      if(params['tokenExpired']){
        console.log(params['tokenExpired']);
        let RefNotDialog = this.dialog.open(NotificationDialog,
          {
            width: '450px',
            disableClose: true,
            data: {
              Type: 'Error',
              Title: 'Sessão Inválida',
              Description: 'Sessão expirada... Faça Login Novamente',
              Action: false,
              Action_Caption: ''
            }
          });
        }
    });

    let color: ColorVariable = {
      light: 'rgba(92, 119, 255, .1)',
      default: 'rgb(0, 162, 232)',
      contrast: 'rgb(255, 255, 255)'
    };
    this.selectColor(color);

    let darkMode = localStorage.getItem('darkMode');
    if(darkMode && darkMode == 'true') {
      this.enableDarkMode();
    }else {
      this.disableDarkMode();
    }
  }

  /**
   * Form Submit
   */
  send() {
    const controls = this.form.controls;
    /** check form */
    if (this.form.invalid) {
      Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
      );
      return;
    }

    //this.loading = true;

    const authData = {
      email: controls['email'].value,
      password: controls['password'].value
    };


    /*
            this.store.dispatch(new Login({authToken: "XRPAISuOIAJDOHAKHDOIAJKLAJDJ"}));
                        localStorage.setItem("FioriUser", "1");

                        this.router.navigateByUrl(this.returnUrl); // Main page
    */

    this.spinnerButtonOptions.active = true;
    this.toggleRememberMe();

    this.auth.login(authData.email, authData.password).pipe(
        tap(user => {
          if (user.Success == true) {

            localStorage.setItem('Username',authData.email);
            localStorage.setItem('FioriUser', user.Login.FioriUser);
            localStorage.setItem('FioriDisplayName', user.Login.DisplayName + ' / ' + user.Login.CardName);
            localStorage.setItem('CardName', user.Login.CardName);

            this.store.dispatch(new Login({authToken: user.Login.Token}));

            this.ReservasService.Get_Profile().subscribe((data: any) => {
              if(data) {
                localStorage.setItem('Profile', data.Profile);
                //Modules Array should come from the Get_Profile Service
                if(data.Modules) {
                  localStorage.setItem('Modules', data.Modules);
                }else {
                  let modules = [
                    {
                      "module":"addresses",
                      "content": []
                    },
                    {
                      "module":"client",
                      //"content": []
                      "content": ['AddContacts','EditContacts','EditSchedules']
                    },
                    {
                      "module":"listcliexpeditions",
                      "content": []
                    },
                    {
                      "module":"listetqexpeditions",
                      "content": []
                    },
                    {
                      "module":"listexpeditions",
                      //"content": []
                      "content": ["maps","POD"]
                    },
                    {
                      "module":"listincidences",
                      "content": []
                    },
                    {
                      "module":"listmaps",
                      "content": []
                    },
                    {
                      "module":"newexpedition",
                      //"content": ['CODPayment']
                      "content": ['LabelSpeed','Payer','Price','Notify','MerchandiseType']
                    }
                  ];
                  if(data.Profile == 'FAT') {
                    modules.push({
                      "module":"listinvexpeditions",
                      "content": []
                    });
                    modules.push({
                      "module":"listinvoices",
                      "content": []
                    });
                  }
                  localStorage.setItem('Modules', btoa(JSON.stringify(modules)));
                }
              }
              this.router.navigateByUrl(this.returnUrl); // Main page
            },
            error =>
            {
              this.router.navigateByUrl(this.returnUrl); // Main page
              console.log(error);
            });

            //this.router.navigate(['/']);
          } else {
            //this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
            this.snackbar.open(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), this.translate.instant('GERAL.CLOSE'), {
              duration: 5000,
              verticalPosition: 'bottom'
            });

          }

        }),
        catchError((err) => {
          this.authNoticeService.setNotice(err.Message, 'danger');
          return of();
        }),


        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
          this.spinnerButtonOptions.active = false;
          this.cd.markForCheck();
        })
    ).subscribe();

  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  toggleRememberMe() {
    let rememberMe = this.form.controls['rememberMe'].value;
    if (rememberMe) {
      let username = this.form.controls['email'].value;
      localStorage.setItem('FioriUsername',username);
    } else {
      localStorage.setItem('FioriUsername','');
    }
  }

  selectColor(color: ColorVariable) {
    if (this.document) {

      this.document.documentElement.style.setProperty('--color-primary', color.default.replace('rgb(', '').replace(')', ''));
      this.document.documentElement.style.setProperty('--color-primary-contrast', color.contrast.replace('rgb(', '').replace(')', ''));
    }
  }

  enableDarkMode() {
    localStorage.setItem('darkMode', 'true');
    this.styleService.setStyle(Style.dark);
  }

  disableDarkMode() {
    localStorage.setItem('darkMode', 'false');
    this.styleService.setStyle(Style.default);
  }
}
