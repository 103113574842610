// Angular
import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';

import { Router } from '@angular/router';

import { environment } from './../../../../../environments/environment';

import { TranslateService, TranslateModule } from '@ngx-translate/core';
import icWarning from '@iconify/icons-ic/warning';
import icError from '@iconify/icons-ic/error';
import icCheck from '@iconify/icons-ic/check-circle';
import { stagger80ms } from '../../../../../@vex/animations/stagger.animation';
import { scaleIn400ms } from '../../../../../@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '../../../../../@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from '../../../../../@vex/animations/fade-in-up.animation';


@Component({
    selector: 'vex-components-overview-demo-dialog',
    template: `
      <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
          <!--<div>{{Title}}</div>-->
          <div *ngIf=" Type == 'Success'" class="inline-block rounded-full text-primary flex items-center justify-center">
              <ic-icon [icon]="icCheck" size="48px" color="#5cb85c" fxFlex="auto" class="mr-2" ></ic-icon>
              <h2 class="headline m-0" fxFlex="auto" style="color:#5cb85c;">{{ 'GERAL.SUCCESS' | translate }}
                  <span *ngIf="Title != '' " style="font-size: 21px;"> - {{Title}}</span></h2>
          </div>
          
          <div *ngIf=" Type == 'Error'" class="inline-block rounded-full text-primary flex items-center justify-center">
              <ic-icon [icon]="icError" size="48px" color="#d9534f" fxFlex="auto" class="mr-2" ></ic-icon>
              <h2 class="headline m-0" fxFlex="auto" style="color:#d9534f;">{{ 'GERAL.ERROR' | translate }}
                  <span *ngIf="Title != '' " style="font-size: 21px;"> - {{Title}}</span></h2>
          </div>
          
          <div *ngIf=" Type == 'Warning'" class="inline-block rounded-full text-primary flex items-center justify-center">
              <ic-icon [icon]="icWarning" size="48px" color="#ffbb33" fxFlex="auto" class="mr-2" ></ic-icon>
              <h2 class="headline m-0" fxFlex="auto" style="color:#ffbb33;">
                  {{ 'GERAL.WARNING' | translate }}<span *ngIf="Title != '' " style="font-size: 21px;"> - {{Title}}</span></h2>
          </div>
          
          <button type="button" mat-icon-button (click)="onCloseClick()" tabindex="-1">
              <mat-icon [icIcon]="icClose"></mat-icon>
          </button>
      </div>

      <mat-dialog-content>
          <p *ngIf="Description_Type!='Array'">{{Description}}</p>
          <div *ngIf="Description_Type=='Array'">
              <p *ngFor="let D of Description">{{D}}</p>
          </div>
      </mat-dialog-content>


      <mat-dialog-actions align="end">
          <button mat-button (click)="onCloseClick()">OK</button>
          <button *ngIf="Action == true" mat-raised-button (click)="onActionClick()" color="primary">{{Action_Caption}}</button>
          <!--<button mat-button (click)="close('No')">NO</button>
          <button mat-button color="primary" (click)="close('Yes')">YES</button>-->
      </mat-dialog-actions>
  `,
    animations: [
        stagger80ms,
        scaleIn400ms,
        fadeInRight400ms,
        fadeInUp400ms
    ]
})

export class NotificationDialog implements OnInit {
	// Public properties
    viewLoading: boolean = false;
    @Output() submitClicked = new EventEmitter<any>();
    
    Type = "Success"; 
    Title ="";
    Description ;
    Description_Type = "Text";
    Action = false;
    Action_Caption = "";
    icClose = icClose;
    icWarning = icWarning;
    icError = icError;
    icCheck = icCheck;
    // Success    
    // Error
    // Warning


	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	constructor(
		public dialogRef: MatDialogRef<NotificationDialog>,
        private translate: TranslateService,
        private router: Router ,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {

        this.Title = data.Title;
        this.Type = data.Type;
        this.Description =data.Description;
        this.Action = data.Action;
        this.Action_Caption = data.Action_Caption;

        if(Array.isArray(this.Description)  )
        {
            this.Description_Type = "Array";
        }



     }

    ngOnInit() 
    {

	}

	/**
	 * Close dialog with false result
	 */
    onCloseClick(): void 
    {

        

        this.dialogRef.close();

        if(this.Type == "Error"  &&  ( this.Description.includes("INVALID_TOKEN")
            || this.Description.includes("INVALIDTOKEN")
            || this.Description.includes("Invalid_Token")
            || this.Description.includes("InvalidToken")
            || this.Description.includes("Invalid session")  ) )
        {
            this.router.navigateByUrl('/login');
            localStorage.removeItem(environment.authTokenKey);
        }

        if(this.Type == "Error" &&  this.Description.includes("sessão expirou")    )
        {
            this.router.navigateByUrl('/login');
            localStorage.removeItem(environment.authTokenKey);
        }


    }
    

    onActionClick(): void 
    {
		this.submitClicked.emit();

	}
}
