<div class="footer" fxLayout="row" fxHide fxShow.gt-xs>
  <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center" vexContainer>
    <!--<a class="action"
       color="primary"
       fxFlex="none"
       href="//1.envato.market/0vkRO"
       id="get-vex"
       mat-raised-button>
      <ic-icon [icon]="icShoppingBasket" class="ltr:mr-2 rtl:ml-2" inline="true" size="18px"></ic-icon>
      <span>Get Vex (Angular 10+)</span>
    </a>-->
    <div class="ltr:ml-4 rtl:mr-4" fxHide fxShow.gt-xs>
      Copyright © {{ year }} - <a href="https://sharpthinkit.com/" target="_blank">
      <span style="font-size: 16px;">Sharp<span style="color:#e22f22;">think</span>
        <span style="font-weight: 600;">IT</span>
      </span>
    </a>
    </div>
  </div>
</div>
