import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from '../../../../services/config.service';
import { Style, StyleService } from '../../../../services/style.service';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icMoveToInbox from '@iconify/icons-ic/twotone-move-to-inbox';
import icListAlt from '@iconify/icons-ic/twotone-list-alt';
import icTableChart from '@iconify/icons-ic/twotone-table-chart';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icDoNotDisturb from '@iconify/icons-ic/twotone-do-not-disturb';
import icOfflineBolt from '@iconify/icons-ic/twotone-offline-bolt';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icLock from '@iconify/icons-ic/twotone-lock';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import icInvertColors from '@iconify/icons-ic/invert-colors';
import icColorLens from '@iconify/icons-ic/color-lens';
import icKey from '@iconify/icons-ic/vpn-key';
import icPdf from '@iconify/icons-ic/picture-as-pdf';
import icAssignment from '@iconify/icons-ic/assignment';
import { Icon } from '@visurel/iconify-angular';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../app/core/reducers';
import { currentUser, Logout, User } from '../../../../../app/core/auth';
// Dialogs
import { ChangePasswordDialog } from '../../../../../app/views/themes/geral/dialogs/ChangePassword.component';
import { SimulatorDialog } from '../../../../../app/views/themes/geral/dialogs/Simulator.component';
import { NotificationDialog } from '../../../../../app/views/themes/geral/dialogs/NotificationDialog.component';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: Icon;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {
  user$: Observable<User>;

  items: MenuItem[] = [
    {
      id: '1',
      icon: icAccountCircle,
      label: 'My Profile',
      description: 'Personal Information',
      colorClass: 'text-teal',
      route: '/apps/social'
    },
    {
      id: '2',
      icon: icMoveToInbox,
      label: 'My Inbox',
      description: 'Messages & Latest News',
      colorClass: 'text-primary',
      route: '/apps/chat'
    },
    {
      id: '3',
      icon: icListAlt,
      label: 'My Projects',
      description: 'Tasks & Active Projects',
      colorClass: 'text-amber',
      route: '/apps/scrumboard'
    },
    {
      id: '4',
      icon: icTableChart,
      label: 'Billing Information',
      description: 'Pricing & Current Plan',
      colorClass: 'text-purple',
      route: '/pages/pricing'
    }
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: icCheckCircle,
      colorClass: 'text-green'
    },
    {
      id: 'away',
      label: 'Away',
      icon: icAccessTime,
      colorClass: 'text-orange'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: icDoNotDisturb,
      colorClass: 'text-red'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: icOfflineBolt,
      colorClass: 'text-gray'
    }
  ];

  configs = this.configService.configs;
  Style = Style;
  selectedStyle$ = this.styleService.style$;
  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;
  icPerson = icPerson;
  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icArrowDropDown = icArrowDropDown;
  icBusiness = icBusiness;
  icVerifiedUser = icVerifiedUser;
  icLock = icLock;
  icNotificationsOff = icNotificationsOff;
  icInvertColors = icInvertColors;
  icColorLens = icColorLens;
  icKey = icKey;
  icPdf = icPdf;
  icAssignment = icAssignment;

  constructor(private configService: ConfigService,
              private styleService: StyleService,
              private cd: ChangeDetectorRef,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
              private store: Store<AppState>,
              private translate: TranslateService,
              public Dialog: MatDialog) { }

  ngOnInit() {
    this.user$ = this.store.pipe(select(currentUser));
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  enableDarkMode() {
    localStorage.setItem('darkMode', 'true');
    this.styleService.setStyle(Style.dark);

  }

  disableDarkMode() {
    localStorage.setItem('darkMode', 'false');
    this.styleService.setStyle(Style.default);
  }

  openSettings() {
    this.popoverRef.close();
    let settingsBtn = <HTMLInputElement>document.getElementById("openSettings");
    if(settingsBtn) { settingsBtn.click(); }
  }

  onDownloadManual() {
    this.popoverRef.close();
  }

  onChangePassword() {

    this.popoverRef.close();

    let dialogRef = this.Dialog.open(ChangePasswordDialog, {
      disableClose: true ,
      closeOnNavigation: true,
      width: '500px',
      panelClass: 'custom-dialog',
      data: { Tile: "Change Password"  }
    });

    /*dialogRef.afterClosed().subscribe(result => {

      if(result){

      }

    });*/

    const dialogSubmitSubscription =
        dialogRef.componentInstance.submitClicked.subscribe(result => {

          dialogSubmitSubscription.unsubscribe();
        });
  }

  onOpenSimulator() {

    this.popoverRef.close();

    let dialogRef = this.Dialog.open(SimulatorDialog, {
      disableClose: true ,
      closeOnNavigation: true,
      panelClass: 'custom-dialog',
      width: '65%',
      data: { Tile: "Change Password"  }
    });

    /*dialogRef.afterClosed().subscribe(result => {

      if(result){

      }

    });*/

    const dialogSubmitSubscription =
        dialogRef.componentInstance.submitClicked.subscribe(result => {

          dialogSubmitSubscription.unsubscribe();
        });
  }

  /**
   * Log out
   */
  close() {
    this.popoverRef.close();
      this.store.dispatch(new Logout());
  }
}
